import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from './../../images/djar-new.png';
import logogreen from './../../images/djar-groen.png';

import './header.scss';
import Container from '../container/container';
// import { getUrlAliases } from '../../helpers/helpers';

const Header = props => {
  const { location } = props;
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          contact {
            location
            phone
            mail
          }
          social {
            facebook
            linkedin
            instagram
            pinterest
          }
        }
      }
      allMenuItems(
        sort: { fields: [weight], order: DESC }
        filter: { weight: { ne: null } }
      ) {
        nodes {
          key
          title
          weight
          relative
          below {
            key
            title
            weight
            relative
            below {
              key
              title
              weight
              relative
            }
          }
        }
      }
      allUrlItems {
        edges {
          node {
            langcode
            nid
            alias
          }
        }
      }
    }
  `);
  const menuToggle = () => {
    document.body.parentElement.classList.toggle('menu-open');
  };

  const [linkClass, setLinkClass] = useState('animations-off');

  // const aliases = getUrlAliases(nid, data.allUrlItems);

  return (
    <>
      {/* <div className="header__notice">
        <p>
          Wij zijn in verlof van 5 juli tot 25 juli. Vanaf 26 juli kan je
          opnieuw bij ons terecht voor healthy food, voor thuis of op kantoor.
        </p>
      </div> */}
      <header
        className={
          location.pathname === '/' || location.pathname === '/en/'
            ? 'home'
            : ''
        }
      >
        <div className="sup-header">
          <Container>
            <ul className="sup-header__links">
              <li>
                <a
                  href="https://maps.google.com/?q=DJAR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={'map-marker-alt'} />
                  {data.site.siteMetadata.contact.location}
                </a>
              </li>
              <li>
                <a
                  href={'tel:' + data.site.siteMetadata.contact.phone}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={'phone'} />
                  {data.site.siteMetadata.contact.phone}
                </a>
              </li>
              <li>
                <a
                  href={'mailto:' + data.site.siteMetadata.contact.mail}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={'envelope'} />
                  {data.site.siteMetadata.contact.mail}
                </a>
              </li>
              <li>
                <ul className="sup-header__social-links">
                  <li>
                    <a
                      title="Facebook"
                      href={data.site.siteMetadata.social.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                    </a>
                  </li>
                  <li>
                    <a
                      title="Instagram"
                      href={data.site.siteMetadata.social.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={['fab', 'instagram']} />
                    </a>
                  </li>
                  <li>
                    <a
                      title="LinkedIn"
                      href={data.site.siteMetadata.social.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                    </a>
                  </li>
                  <li>
                    <a
                      title="Pinterest"
                      href={data.site.siteMetadata.social.pinterest}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={['fab', 'pinterest-p']} />
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li className="lang-toggle">
                {lang === 'nl' ? 'NL' : 'EN'}
                {aliases.en.length && aliases.nl.length ? (
                  <>
                    <FontAwesomeIcon icon={['fas', 'caret-down']} />
                    <ul className="lang-options">
                      <li>
                        {lang === 'nl' ? (
                          <a
                            href={
                              location.pathname === '/'
                                ? '/en'
                                : aliases.en[0]?.node.alias
                            }
                          >
                            EN
                          </a>
                        ) : (
                          <a
                            href={
                              location.pathname === '/en/'
                                ? `/`
                                : aliases.nl[0]?.node.alias
                            }
                          >
                            NL
                          </a>
                        )}
                      </li>
                    </ul>
                  </>
                ) : null}
              </li> */}
            </ul>
          </Container>
        </div>
        <Container>
          <div className="logo">
            <Link to="/">
              <img
                alt="DJAR-logo"
                title="DJAR"
                src={
                  location.pathname === '/' || location.pathname === '/en/'
                    ? logo
                    : logogreen
                }
              ></img>
            </Link>
          </div>
          <div className="menu-toggle" onClick={menuToggle}>
            <FontAwesomeIcon icon={'bars'} size="lg" />
          </div>
          <nav>
            <div className="close" onClick={menuToggle}>
              <Container>
                <FontAwesomeIcon icon={'caret-left'} />
                TERUG
              </Container>
            </div>
            <ul className="navigation__main">
              {data.allMenuItems.nodes
                .sort((a, b) => {
                  if (parseInt(a.weight) < parseInt(b.weight)) {
                    return -1;
                  }
                  if (parseInt(a.weight) > parseInt(b.weight)) {
                    return 1;
                  }
                  return 0;
                })
                .map((item, index) => {
                  return index + 1 === data.allMenuItems.nodes.length ? (
                    <li key={item.key} className={item.below ? 'below' : 'btn'}>
                      <Link
                        to={item.relative}
                        onClick={() => {
                          setLinkClass('animations-off');
                          menuToggle();
                        }}
                        onMouseOver={() => setLinkClass('')}
                        onMouseOut={() => setLinkClass('')}
                        className={[
                          location.pathname.indexOf(item.relative) > -1
                            ? item.relative === '/' &&
                              (location.pathname !== item.relative ||
                                location.pathname !== `${item.relative}en/`)
                              ? ''
                              : 'active'
                            : '',
                          linkClass
                        ].join(' ')}
                      >
                        {item.title}
                        {item.below && <FontAwesomeIcon icon={'caret-down'} />}
                      </Link>
                      {item.below ? (
                        <ul className="dropdown">
                          {item.below.map(item => {
                            return (
                              <li key={item.key}>
                                <Link
                                  to={item.relative}
                                  onClick={() => menuToggle()}
                                >
                                  {item.title}
                                  {item.below && (
                                    <FontAwesomeIcon icon={'caret-down'} />
                                  )}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ''
                      )}
                    </li>
                  ) : (
                    <li key={item.key} className={item.below ? 'below' : ''}>
                      <Link
                        to={item.relative}
                        onClick={() => {
                          setLinkClass('animations-off');
                          menuToggle();
                        }}
                        onMouseOver={() => setLinkClass('')}
                        onMouseOut={() => setLinkClass('')}
                        className={[
                          location.pathname.indexOf(item.relative) > -1
                            ? item.relative === '/' &&
                              (location.pathname !== item.relative ||
                                location.pathname !== `${item.relative}en/`)
                              ? ''
                              : 'active'
                            : '',
                          linkClass
                        ].join(' ')}
                      >
                        {item.title}
                        {item.below && <FontAwesomeIcon icon={'caret-down'} />}
                      </Link>
                      {item.below ? (
                        <ul className="dropdown">
                          {item.below.map(item => {
                            return (
                              <li key={item.key}>
                                <Link
                                  to={item.relative}
                                  onClick={() => menuToggle()}
                                >
                                  {item.title}
                                  {item.below && (
                                    <FontAwesomeIcon icon={'caret-right'} />
                                  )}
                                  {item.below && (
                                    <ul className="second-dropdown">
                                      {item.below.map((item, i) => {
                                        return (
                                          <li key={item.key}>
                                            <Link to={item.relative}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ''
                      )}
                    </li>
                  );
                })}
            </ul>
          </nav>
        </Container>
      </header>
    </>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired
};

export default Header;
