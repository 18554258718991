import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './footer.scss';
import Container from '../container/container';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          contact {
            location_p1
            location_p2
            phone
            mail
          }
          social {
            facebook
            linkedin
            instagram
            pinterest
          }
        }
      }
      allMenuItems(
        sort: { fields: [weight], order: DESC }
        filter: { weight: { ne: null } }
      ) {
        nodes {
          key
          title
          weight
          relative
        }
      }
    }
  `);

  return (
    <footer>
      <div className="main-footer">
        <Container>
          <div className="main-footer__col">
            <h3>
              <em>Volg</em> ons
            </h3>
            <ul>
              <li>
                <a
                  title="Facebook"
                  href={data.site.siteMetadata.social.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  Facebook
                </a>
              </li>
              <li>
                <a
                  title="Instagram"
                  href={data.site.siteMetadata.social.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'instagram']} />
                  Instagram
                </a>
              </li>
              <li>
                <a
                  title="LinkedIn"
                  href={data.site.siteMetadata.social.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  title="Pinterest"
                  href={data.site.siteMetadata.social.pinterest}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'pinterest-p']} />
                  Pinterest
                </a>
              </li>
            </ul>
          </div>
          <div className="main-footer__col">
            <h3>Sitemap</h3>
            <ul>
              {data.allMenuItems.nodes.map(item => (
                <li key={item.key}>
                  <Link to={item.relative}>{item.title}</Link>
                </li>
              ))}
              <li key="contact">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="main-footer__col">
            <h3>Onze formules</h3>
            <ul>
              <li>
                <Link to="/djar-work">DJAR @ Work</Link>
              </li>
            </ul>
          </div>
          <div className="main-footer__col">
            <h3>Contact</h3>
            <ul>
              <li>
                <a
                  className="icon"
                  href="https://maps.google.com/?q=DJAR"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Navigeer naar DJAR"
                >
                  <FontAwesomeIcon icon={['fa', 'location-arrow']} />
                  {data.site.siteMetadata.contact.location_p1}
                  <br />
                  {data.site.siteMetadata.contact.location_p2}
                </a>
              </li>
              <li>
                <a
                  className="icon"
                  href={'tel:' + data.site.siteMetadata.contact.phone}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Bel naar DJAR"
                >
                  <FontAwesomeIcon icon={['fa', 'phone']} />
                  {data.site.siteMetadata.contact.phone}
                </a>
              </li>
              <li>
                <a
                  className="icon"
                  href={'mailto:' + data.site.siteMetadata.contact.mail}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Mail naar DJAR"
                >
                  <FontAwesomeIcon icon={['fa', 'envelope']} />
                  {data.site.siteMetadata.contact.mail}
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div className="sub-footer">
        <Container>
          <ul>
            <li>&copy; 2021 DJAR</li>
            <li>Funky food for the healthy soul</li>
            <li className="link">
              <Link to="/algemene-voorwaarden">Algemene voorwaarden</Link>
            </li>
            <li className="link">
              <Link to="/privacybeleid">Privacybeleid</Link>
            </li>
            <li className="link">
              <Link to="/cookiebeleid">Cookiebeleid</Link>
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ``
};

export default Footer;
