export function parseDrupalURI(uri, langCode = 'nl') {
  if (uri)
    return uri
      .replace('internal:/', '')
      .replace('internal:#', '#')
      .replace('external://', '')
      .replace('entity:', '/');
  // else if (uri && langCode === 'en')
  //   return uri
  //     .replace('internal:/', '/en/')
  //     .replace('internal:#', '#')
  //     .replace('external://', '')
  //     .replace('entity:', '/en/');
  else return uri;
}

export function getUrlAliases(nid, urls) {
  const nl = urls.edges.filter(
    url => url.node.nid === `${nid}` && url.node.langcode === 'nl'
  );
  const en = urls.edges.filter(
    url => url.node.nid === `${nid}` && url.node.langcode === 'en'
  );
  return {
    nl,
    en
  };
}
