/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { getUrlAliases } from '../helpers/helpers';

function SEO(props) {
  const { description, lang, meta, title, nid, location } = props;
  const { site, allUrlItems } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        allUrlItems {
          edges {
            node {
              langcode
              nid
              alias
            }
          }
        }
      }
    `
  );

  if (location) {
    if (location.pathname === '/' || location.pathname === '/en/') {
      var langLinkEN = '/';
      var langLinkNL = '/en';
    }
  }
  if (nid) {
    const aliases = getUrlAliases(nid, allUrlItems);
    var nlPage = aliases.nl;
    var enPage = aliases.en;
  }
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          name: `image`,
          property: `og:image`,
          content: `https://cms.djar.fit/sites/default/files/styles/story_paragraph/public/2019-11/djar_c_the_fresh_light_095.jpg`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:image`,
          content: `https://cms.djar.fit/sites/default/files/styles/story_paragraph/public/2019-11/djar_c_the_fresh_light_095.jpg`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      {nid ? (
        <link
          rel="alternate"
          href={`https://www.djar.fit${
            langLinkEN ? langLinkEN : nlPage[0]?.node.alias
          }`}
          hrefLang={'nl'}
        />
      ) : (
        ''
      )}
      {nid && enPage.length ? (
        <link
          rel="alternate"
          href={`https://www.djar.fit${
            langLinkNL ? langLinkNL : enPage[0]?.node.alias
          }`}
          hrefLang={'en'}
        />
      ) : (
        ''
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
