/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header/header';
import Footer from '../footer/footer';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faLocationArrow,
  faLongArrowAltRight,
  faBars,
  faCaretDown,
  faCaretLeft,
  faChevronDown,
  faChevronUp,
  faCaretRight
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faPinterestP,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

import './layout.scss';
import { ParallaxProvider } from 'react-scroll-parallax';

library.add(
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faFacebookF,
  faLinkedinIn,
  faLocationArrow,
  faInstagram,
  faPinterestP,
  faLongArrowAltRight,
  faBars,
  faCaretDown,
  faCaretLeft,
  faChevronDown,
  faChevronUp,
  faCaretRight
);

const Layout = props => {
  const { children, location, lang, nid } = props;
  return (
    <>
      <ParallaxProvider>
        <div className="djar__site-layout">
          <Header location={location} lang={lang} nid={nid} />
          {children}
          <Footer />
        </div>
      </ParallaxProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
